<template>
  <div>
    <!-- Show the table view -->
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :loading="isMakingRequest"
      :page="currentPage"
      :server-items-length="response.total || 0"
      :items-per-page="perPage"
      @update:page="_ => { currentPage = _; fetchStories(); }"
      @update:items-per-page="_ => { perPage = _; fetchStories() }"
      class="elevation-1 stories-data-table"
      item-key="id"
    >
      <!-- Modify the influencer column -->
      <template v-slot:item.username="{ item }">
        <profile-chip
          :platform="item.platform"
          :data="item"
        />
      </template>

      <template v-slot:item.thumbnail="{ item }">
        <div class="pointer">
          <v-img
            v-if="item.thumbnail"
            @click="selectedItem = item; shouldShowPreviewDialog = true;"
            :src="storageUrl(item.thumbnail)"
            crossorigin="anonymous"
            max-width="32"
            height="32"
            cover
          />
        </div>
      </template>

      <!-- Show the date -->
      <template v-slot:item.posted_at="{ item }">
        {{ dayjs(item.posted_at).format("ll") }}
      </template>

      <!-- Modify the total followers column -->
      <template v-slot:item.followers="{ item }">
        <span :title="item.followers">
          {{ item.followers ? nFormatter(item.followers) : 'NA' }}
        </span>
      </template>

      <!-- Modify the reach column -->
      <template v-slot:item.reach="{ item }">
        <span :title="item.reach">
          {{ nFormatter(item.reach) }}
        </span>
      </template>

      <!-- Modify the impressions column -->
      <template v-slot:item.impressions="{ item }">
        <span :title="item.impressions">
          {{ nFormatter(item.impressions) }}
        </span>
      </template>

      <!-- Modify the replies column -->
      <template v-slot:item.replies="{ item }">
        <span :title="item.replies">
          {{ nFormatter(item.replies) }}
        </span>
      </template>

      <!-- Modify the reactions column -->
      <template v-slot:item.reactions="{ item }">
        <span :title="item.reactions">
          {{ nFormatter(item.reactions) }}
        </span>
      </template>

      <!-- Modify the shares column -->
      <template v-slot:item.shares="{ item }">
        <span :title="item.shares">
          {{ nFormatter(item.shares) }}
        </span>
      </template>

      <!-- Modify the sticker_taps column -->
      <template v-slot:item.sticker_taps="{ item }">
        <span :title="item.sticker_taps">
          {{ nFormatter(item.sticker_taps) }}
        </span>
      </template>

      <!-- Modify the website_clicks column -->
      <template v-slot:item.website_clicks="{ item }">
        <span :title="item.website_clicks">
          {{ nFormatter(item.website_clicks) }}
        </span>
      </template>

      <!-- Modify the actions column -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end">
          <v-btn
            v-if="overview.canUserWrite"
            small
            depressed
            color="primary"
            @click="showEditDialog(item)"
          >
            Edit

            <v-icon right>
              edit
            </v-icon>
          </v-btn>

          <!-- Show menu options -->
          <v-menu
            v-if="overview.canUserWrite"
            transition="slide-y-transition"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="120" dense>
              <!-- Show the edit button -->
              <v-list-item
                @click="showDeleteDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete Story
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- Show the preview dialog -->
    <v-dialog
      v-model="shouldShowPreviewDialog"
      max-width="338"
    >
      <v-card-text
        v-if="selectedItem"
        class="px-0 py-0"
      >
        <v-img
          :src="storageUrl(selectedItem.thumbnail)"
          class="white"
          max-width="338"
          max-height="600"
          contain
        />
      </v-card-text>
    </v-dialog>

    <!-- Show a delete story dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingDeleteRequest"
      max-width="500"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="primary white--text">
          Delete Story?
        </v-card-title>

        <v-card-text class="pt-4">
          <p>
            Are you sure you want to delete the story posted by <strong>{{ selectedItem.username }}</strong>?
          </p>
        </v-card-text>

        <v-card-actions class="pb-4 pr-6">
          <v-spacer />

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingDeleteRequest"
            :loading="isMakingDeleteRequest"
            @click="handleDeleteStory"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show an edit story dialog -->
    <template v-if="selectedItem">
      <edit-story
        :overview="overview"
        v-model="shouldShowEditDialog"
        module="campaignTracking"
        :model="selectedItem"
      />
    </template>
  </div>
</template>

<script>
// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const EditStory = () => import(/* webpackChunkName: "crm-edit-story" */ "@/components/crm/EditStory.vue")

// Export the SFC
export default {
  // Name of the component
  name: "StoriesTable",

  // Register children components
  components: {
    ProfileChip,
    StatusChip,
    EditStory
  },

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    },

    shouldShowActions: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  // Define local data variables
  data: () => ({
    // The selected story model
    selectedItem: null,

    // Whether or not to show the dialogs
    shouldShowEditDialog: false,
    shouldShowDeleteDialog: false,
    shouldShowPreviewDialog: false,

    // Whether or not a request is being made currently
    isMakingRequest: false,
    isMakingEditRequest: false,
    isMakingDeleteRequest: false,
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get all the table header items
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        {
          text: "Influencer",
          value: "username",
          sortable: false,
          sort: (a, b) => a.localeCompare(b)
        },
        {
          text: "",
          value: "thumbnail",
          sortable: false,
        },
        {
          text: "Posted",
          value: "posted_at",
          sortable: false,
          sort: (a, b) => dayjs(a).diff(dayjs(b))
        },
        {
          text: "Followers",
          value: "followers",
          sortable: false
        },
        {
          text: "Reach",
          value: "reach",
          sortable: false,
          condition: this.tableItems.some((item) => item.reach > 0)
        },
        {
          text: "Impressions",
          value: "impressions",
          sortable: false,
          condition: this.tableItems.some((item) => item.impressions > 0)
        },
        {
          text: "Replies",
          value: "replies",
          sortable: false,
          condition: this.tableItems.some((item) => item.replies > 0)
        },
        {
          text: "Reactions",
          value: "reactions",
          sortable: false,
          condition: this.tableItems.some((item) => item.reactions > 0)
        },
        {
          text: "Shares",
          value: "shares",
          sortable: false,
          condition: this.tableItems.some((item) => item.shares > 0)
        },
        {
          text: "Sticker Taps",
          value: "sticker_taps",
          sortable: false,
          condition: this.tableItems.some((item) => item.sticker_taps > 0)
        },
        {
          text: "Website Clicks",
          value: "website_clicks",
          sortable: false,
          condition: this.tableItems.some((item) => item.website_clicks > 0)
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "right",
          condition: this.shouldShowActions === true
        }
      ].filter((item) => typeof item.condition === "boolean" ? item.condition : true)
    },

    /**
     * Get all the mapped data objects for table view
     *
     * @returns {Array}
     */
    tableItems() {
      return (this.response?.data || []).map((item) => {
        return {
          id: item.id,
          platform: item.platform,
          username: item.username,
          fullname: item.fullname,
          thumbnail: item.thumbnail,
          posted_at: item.posted_at,
          followers: item.followers,
          picture: item.picture,
          is_verified: item.is_verified,
          reach: item.reach,
          impressions: item.impressions,
          replies: item.replies,
          reactions: item.reactions,
          shares: item.shares,
          sticker_taps: item.sticker_taps,
          website_clicks: item.website_clicks,
        }
      })
    },

    /**
     * Get the query object for the stories
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["campaignTracking/storiesTableQueryById"](this.overview.model.id)
    },

    /**
     * Get the current page number
     *
     * @returns {Number}
     */
    currentPage: {
      get() {
        return this.query.pagination.page
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updateStoriesTableQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              page: value
            }
          }
        })
      }
    },

    /**
     * Get the current per page value
     *
     * @returns {Number}
     */
    perPage: {
      get() {
        return this.query.pagination.perPage
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updateStoriesTableQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              perPage: value
            }
          }
        })
      }
    },

    /**
     * Get the response object for the stories
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["campaignTracking/storiesTableResponseById"](this.overview.model.id)
    },

    /**
     * Get the selected platform
     *
     * @returns {String}
     */
    selectedPlatform() {
      return this.$store.getters["campaignTracking/selectedPlatformById"](this.overview.model.id)
    },

    /**
     * Get the selected influencer
     *
     * @returns {Object}
     */
    selectedInfluencer() {
      return this.$store.getters["campaignTracking/selectedInfluencerById"](this.overview.model.id)
    }
  },

  // Set watchers for local data
  watch: {
    // Whenever the selected influencer changes
    selectedInfluencer: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchStories()
      },

      deep: true
    }
  },

  // Define local method functions
  methods: {
    /**
     * Show a dialog to the user
     *
     * @param {Object} item
     * @returns {void}
     */
    showEditDialog(item) {
      // Mark the object as selected
      this.selectedItem = item

      // Show the dialog
      this.shouldShowEditDialog = true
    },

    /**
     * Show a dialog to the user
     *
     * @param {Object} item
     * @returns {void}
     */
    showDeleteDialog(item) {
      // Mark the object as selected
      this.selectedItem = item

      // Show the dialog
      this.shouldShowDeleteDialog = true
    },

    /**
     * Search for stories
     *
     * @returns {void}
     */
    async fetchStories() {
      // Show a loader
      const loaderId = Symbol("CampaignTracking/fetchStoriesTable")
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Try making a network request
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.query.pagination.page,
            per_page: this.query.pagination.perPage,

            platform: this.selectedPlatform,
            influencer: this.selectedInfluencer ? this.selectedInfluencer.id : null,
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/campaign-tracking/${this.overview.model.id}/stories?${queryParams}`)

        // Update the response object
        this.$store.dispatch("campaignTracking/updateStoriesTableResponse", {
          id: this.overview.model.id,
          response: response.data
        })
      }
      // Catch an error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch stories!" })

        // Log it
        logger({ type: "CampaignTracking/fetchStoriesTable", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Make a network request to delete this story
     *
     * @returns {void}
     */
    async handleDeleteStory() {
      // Show a loader
      this.isMakingDeleteRequest = true

      // Try making a network request
      try {
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/stories/${this.selectedItem.id}`,
          method: "DELETE",
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Story removed!" })

        // Hide the dialog
        this.shouldShowDeleteDialog = false

        // Dispatch an event to refresh the values
        window.dispatchEvent(new CustomEvent("campaignTracking:refreshStories", { detail: this.overview.model.id }))

        // Refresh local data
        this.fetchStories()
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "CampaignTracking/Stories Delete Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nonetheless
      finally {
        this.isMakingDeleteRequest = false
      }
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If the query value is not set
    if (!this.query) {
      // Set the default query value
      this.$store.dispatch("campaignTracking/updateStoriesTableQuery", {
        id: this.overview.model.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_viewed"
        }
      })
    }

    // Ask to fetch the data from the server again
    this.fetchStories()
  }
}
</script>

<style lang="stylus">
.stories-data-table thead tr th
  white-space nowrap !important
.stories-data-table tbody tr td
  white-space nowrap !important
</style>
