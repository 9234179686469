var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 stories-data-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"loading":_vm.isMakingRequest,"page":_vm.currentPage,"server-items-length":_vm.response.total || 0,"items-per-page":_vm.perPage,"item-key":"id"},on:{"update:page":function (_) { _vm.currentPage = _; _vm.fetchStories(); },"update:items-per-page":function (_) { _vm.perPage = _; _vm.fetchStories() }},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('profile-chip',{attrs:{"platform":item.platform,"data":item}})]}},{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pointer"},[(item.thumbnail)?_c('v-img',{attrs:{"src":_vm.storageUrl(item.thumbnail),"crossorigin":"anonymous","max-width":"32","height":"32","cover":""},on:{"click":function($event){_vm.selectedItem = item; _vm.shouldShowPreviewDialog = true;}}}):_vm._e()],1)]}},{key:"item.posted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.posted_at).format("ll"))+" ")]}},{key:"item.followers",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.followers}},[_vm._v(" "+_vm._s(item.followers ? _vm.nFormatter(item.followers) : 'NA')+" ")])]}},{key:"item.reach",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.reach}},[_vm._v(" "+_vm._s(_vm.nFormatter(item.reach))+" ")])]}},{key:"item.impressions",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.impressions}},[_vm._v(" "+_vm._s(_vm.nFormatter(item.impressions))+" ")])]}},{key:"item.replies",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.replies}},[_vm._v(" "+_vm._s(_vm.nFormatter(item.replies))+" ")])]}},{key:"item.reactions",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.reactions}},[_vm._v(" "+_vm._s(_vm.nFormatter(item.reactions))+" ")])]}},{key:"item.shares",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.shares}},[_vm._v(" "+_vm._s(_vm.nFormatter(item.shares))+" ")])]}},{key:"item.sticker_taps",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.sticker_taps}},[_vm._v(" "+_vm._s(_vm.nFormatter(item.sticker_taps))+" ")])]}},{key:"item.website_clicks",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.website_clicks}},[_vm._v(" "+_vm._s(_vm.nFormatter(item.website_clicks))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.overview.canUserWrite)?_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.showEditDialog(item)}}},[_vm._v(" Edit "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" edit ")])],1):_vm._e(),(_vm.overview.canUserWrite)?_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"width":"120","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Delete Story ")])],1)],1)],1)],1):_vm._e()],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"338"},model:{value:(_vm.shouldShowPreviewDialog),callback:function ($$v) {_vm.shouldShowPreviewDialog=$$v},expression:"shouldShowPreviewDialog"}},[(_vm.selectedItem)?_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-img',{staticClass:"white",attrs:{"src":_vm.storageUrl(_vm.selectedItem.thumbnail),"max-width":"338","max-height":"600","contain":""}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":_vm.isMakingDeleteRequest,"max-width":"500"},model:{value:(_vm.shouldShowDeleteDialog),callback:function ($$v) {_vm.shouldShowDeleteDialog=$$v},expression:"shouldShowDeleteDialog"}},[(_vm.selectedItem)?_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Delete Story? ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('p',[_vm._v(" Are you sure you want to delete the story posted by "),_c('strong',[_vm._v(_vm._s(_vm.selectedItem.username))]),_vm._v("? ")])]),_c('v-card-actions',{staticClass:"pb-4 pr-6"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":_vm.isMakingDeleteRequest,"loading":_vm.isMakingDeleteRequest},on:{"click":_vm.handleDeleteStory}},[_vm._v(" Continue ")])],1)],1):_vm._e()],1),(_vm.selectedItem)?[_c('edit-story',{attrs:{"overview":_vm.overview,"module":"campaignTracking","model":_vm.selectedItem},model:{value:(_vm.shouldShowEditDialog),callback:function ($$v) {_vm.shouldShowEditDialog=$$v},expression:"shouldShowEditDialog"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }